<template>
  <div></div>
</template>

<script>
  import { getArg } from '@/utils/index'
  import passportApi from '@/api/passport'
  import myApis from '@/api/my'
  import wechatApi from '@/api/user/wechat'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        code: '',
        wxConfig: {},
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    created() {
      this.code = getArg('code')
      if (window.sessionStorage.openId) {
        this.$router.replace({
          path: '/',
        })
      } else {
        this.getInit()
      }
    },
    async mounted() {
      await this.getWxConfig()
      this.initWxConfig()
    },
    methods: {
      getInit() {
        passportApi
          .init({
            code: this.code,
            systemId: this.systemInfo.systemId,
          })
          .then(res => {
            console.log(res)
            window.sessionStorage.openId = res.openId
            this.login()
          })
          .catch(err => {
            console.log(err)
          })
      },
      async login() {
        await this.$store.dispatch('fetchUserInfo')
        const res = await myApis.getUserInfo()
        this.$store.commit('setUserInfo', res)
        await this.$store.dispatch('getMyInfo') // 获取基本信息
        this.$router.replace({
          path: '/',
        })
      },

      async getWxConfig() {
        try {
          this.wxConfig = await wechatApi.signURL()

          /**
           * zdd 开发测试环境 appId
           *
           */
          // this.wxConfig.appId = `wxaecfcb5051393896`
          /**
           *
           */
        } catch (error) {
          console.error(error)
        }
      },
      initWxConfig() {
        const { appId, timestamp, nonceStr, signature } = this.wxConfig
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'], // 必填，需要使用的JS接口列表
        })
      },
    },
  }
</script>

<style></style>
